<template>
  <div
    class="globalTemplate"
    :style="{ fontFamily: this.cv.typeOfLetter, textAlign: this.cv.align }"
  >
    <div class="westSeccionDark">
      <img :src="cv.img" alt="candidate picture" />
      <div class="contactDark">
        <section>
          <i class="fas fa-map-marker-alt"></i>
          {{ cv.contact.address }}, {{ cv.contact.city }},
          {{ cv.contact.postal }}
        </section>
        <section>
          <i class="fas fa-phone-alt"></i>
          {{ cv.contact.phone }}
        </section>
        <section>
          <i class="fas fa-envelope"></i>
          {{ cv.contact.email }}
        </section>
      </div>

      <h3>{{$t('aboutMe')}}</h3>
      <hr />
      <p class="verifyPagesCv">
        {{ cv.bio }}
      </p>
      <h3>{{$t('skill.skills')}}</h3>
      <hr />
      <div
        class="verifyPagesCv"
        v-for="(item, index) in cv.listSkill"
        :key="index"
      >
        <p>{{ item.name }}</p>
        <div class="progress">
          <div :class="getStyle(item.level)"></div>
        </div>
      </div>
    </div>

    <div class="eastSeccionDark">
      <div>
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length > 0">
          {{ cv.listExperience[0].job }}
        </h2>
      </div>
      <h3>{{$t('experience.experience')}}</h3>
      <hr />
      <section v-for="(item, index) in cv.listExperience" :key="'A' + index">
        <div class="mainInfo verifyPagesCv">
          <h4>{{ item.employer }}</h4>
          <p>{{ item.city }}</p>
          <p>
            {{ item.dateStart ? formatDate(item.dateStart) : "..." }} ~
            {{ item.checkbox ? "Presente" : formatDate(item.dateEnd) }}
          </p>
        </div>

        <div class="detailInfo verifyPagesCv">
          <h4>{{ item.job }}</h4>
          <p>{{ item.bio }}</p>
        </div>
      </section>
      <h3 class="verifyPagesCv">{{$t('training.training')}}</h3>
      <hr />
      <section v-for="(item, index) in cv.listEducation" :key="'B' + index">
        <div class="mainInfo verifyPagesCv">
          <h4>{{ item.nameSchool }}</h4>
          <p>{{ item.city }}</p>
          <p>{{ item.date ? formatDate(item.date) : "..." }}</p>
        </div>
        <div class="detailInfo verifyPagesCv">
          <h4>{{ item.degree }}</h4>
          <p>{{ item.bio }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`;
    },
  },
  mounted() {
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  },
};
</script>
<style scoped>
h1 {
  font-size: 2em;
}
h1,
h2 {
  text-transform: uppercase;
}
h1,
h3 {
  font-family: Montserrat;
}
i {
  padding-right: 3%;
}
img {
  width: 80%;
  border-radius: 50%;
  object-fit: fill;
  margin: auto;
  display: block;
}
.eastSeccionDark {
  width: 64%;
  padding: 2em 1.9em;
  min-height: 100vh;
  height: 100%;
  float: right;
  padding-bottom: 20px;
}
.contactDark {
  width: 100%;
  text-align: center;
  margin-top: 1em;
}
.westSeccionDark {
  display: block;
  width: 36%;
  float: left;
  min-height: 100vh;
  height: 100%;
  padding: 3em;
  color: white;
  background-color: black;
}
h3 {
  font-size: 1.3em;
  margin-top: 1em;
}
h4 {
  font-size: 1.2em;
}
hr {
  margin: 0.3em 0 1em 0;
  width: 100%;
}
.westSeccionDark hr {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.westSeccionDark p {
  margin: 1em 0 0 0;
}
.eastSeccionDark hr {
  border: 1px solid #e0e0e0;
}
.eastSeccionDark section {
  display: flex;
}
.globalTemplate {
  display: flex;
  height: 100%;
}
.mainInfo {
  width: 30%;
  float: left;
}
.mainInfo p {
  color: #828282;
  margin: 0 0.8em 0 0;
  font-weight: 500;
}
.detailInfo {
  width: 70%;
}
</style>
