<template>
  <div
    class="globalTemplate"
    :style="{ fontFamily: this.cv.typeOfLetter, textAlign: this.cv.align }"
  >
    <div class="topCascade">
      <div id="bio">
        <div>
          <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
          <h2 v-if="cv.listExperience.length > 0">
            - {{ cv.listExperience[0].job }} -
          </h2>
        </div>
        <p>{{ cv.bio }}</p>
      </div>
      <div id="photo">
        <img v-bind:src="cv.img" alt="candidate picture" />
      </div>
    </div>
    <div class="mainCascade">
      <div class="westSection">
        <div>
          <h2>{{$t('training.training')}}</h2>
          <section
            v-for="(item, index) in cv.listEducation"
            :key="index"
            class="verifyPagesCv"
          >
            <h4>{{ item.degree }}</h4>
            <p class="location">
              {{ item.nameSchool }} | {{ item.city }} |
              {{ item.date ? formatDate(item.date) : "..." }}
            </p>
            <p>{{ item.bio }}</p>
          </section>
          <h2>{{$t('skill.skills')}}</h2>
          <div
            v-for="(item, index) in cv.listSkill"
            :key="'B' + index"
            class="verifyPagesCv"
          >
            <p class="noMarginBottom">{{ item.name }}</p>
            <div class="progress-cascade">
              <div v-bind:class="getStyle(item.level)"></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <section id="contactInfo">
          <h2>{{$t('contact.contact')}}</h2>
          <div>
            <p>
              <i class="fas fa-map-marker-alt"></i>
              {{ cv.contact.address }}, {{ cv.contact.city }},
              {{ cv.contact.postal }}
            </p>
            <p>
              <i class="fas fa-phone-alt"></i>
              {{ cv.contact.phone }}
            </p>
            <p>
              <i class="fas fa-envelope"></i>
              {{ cv.contact.email }}
            </p>
          </div>
        </section>
        <h2>{{$t('experience.experience')}}</h2>
        <section
          v-for="(item, index) in cv.listExperience"
          :key="'A' + index"
          class="experienceBlock verifyPagesCv"
        >
          <h4>{{ item.job }}</h4>
          <p class="location">
            {{ item.employer }} | {{ item.city }} |
            {{ item.dateStart ? formatDate(item.dateStart) : "..." }} ~
            {{ item.checkbox ? "Presente" : formatDate(item.dateEnd) }}
          </p>
          <p>{{ item.bio }}</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`;
    },
  },
  mounted() {
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  },
};
</script>

<style scoped>
.globalTemplate {
  background-color: #283041 !important;
}
i {
  color: #fff177;
}
h1 {
  font-family: Playfair Display;
  font-size: 3em;
  font-weight: 500;
}
h2 {
  font-family: Playfair Display;
  font-size: 1.7em;
  font-weight: 500;
  color: #cf9604;
  text-transform: uppercase;
}
h4 {
  color: #ffffff;
}
img {
  width: 80%;
  border-radius: 50%;
  object-fit: fill;
  margin: auto;
  display: block;
}
.topCascade {
  padding: 0 3em;
  display: flex;
  align-items: stretch;
  padding-top: 3em;
  background-color: white;
}
#bio {
  width: 60%;
  color: #161616;
}
#bio h2 {
  color: #161616;
  font-weight: normal;
}
#bio p {
  margin-bottom: 1em;
}
#photo {
  width: 40%;
  float: right;
}
.mainCascade {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3em;
}
.mainCascade p {
  color: white;
}
#contactInfo {
  margin-bottom: 2em;
}
#contactInfo p {
  color: #fff177;
  margin: 0;
  line-height: 1.8em;
}

.mainCascade .location {
  color: #8b8b8b;
  font-weight: bold;
  margin-bottom: 0.4em;
}
.westSection {
  width: 120%;
  float: left;
  padding-right: 3em;
}
.noMarginBottom {
  margin-bottom: 0;
  padding-top: 1em;
}
</style>
