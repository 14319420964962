<template>
  <div
    class="globalTemplate"
    :style="{ fontFamily: this.cv.typeOfLetter, textAlign: this.cv.align }"
  >
    <div class="topSimple">
      <section>
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length > 0">
          {{ cv.listExperience[0].job }}
        </h2>
        <div>
          <p>
            <i class="fas fa-map-marker-alt"></i>
            {{ cv.contact.address }}, {{ cv.contact.city }},
            {{ cv.contact.postal }}
          </p>
          <p>
            <i class="fas fa-phone-alt"></i>
            {{ cv.contact.phone }}
          </p>
          <p>
            <i class="fas fa-envelope"></i>
            {{ cv.contact.email }}
          </p>
        </div>
      </section>
      <section>
        <h2>{{$t('aboutMe')}}</h2>
        <p>{{ cv.bio }}</p>
      </section>
    </div>
    <div class="mainSimple">
      <section class="westSimple">
        <img v-bind:src="cv.img" alt="candidate picture" />
        <h2>{{$t('training.training')}}</h2>
        <div
          v-for="(item, index) in cv.listEducation"
          :key="index"
          class="experienceClass-simple verifyPagesCv"
        >
          <h3>{{ item.degree }}</h3>
          <h4>
            {{ item.nameSchool }} | {{ item.city }} |
            {{ item.date ? formatDate(item.date) : "..." }}
          </h4>
          <p>{{ item.bio }}</p>
        </div>
      </section>
      <section class="eastSimple">
        <h2>{{$t('experience.experience')}}</h2>
        <div
          v-for="(item, index) in cv.listExperience"
          :key="'A' + index"
          class="verifyPagesCv"
        >
          <h3>{{ item.job }}</h3>
          <h4>
            {{ item.employer }} | {{ item.city }} |
            {{ item.dateStart ? formatDate(item.dateStart) : "..." }} ~
            {{ item.checkbox ? "Presente" : formatDate(item.dateEnd) }}
          </h4>
          <p>{{ item.bio }}</p>
        </div>
        <h2>{{$t('skill.skills')}}</h2>
        <div
          class="skills"
          v-for="(item, index) in cv.listSkill"
          :key="'B' + index"
        >
          {{ item.name }}
          <div class="progress-simple verifyPagesCv">
            <div v-bind:class="getStyle(item.level)"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`;
    },
  },
  mounted() {
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  },
};
</script>

<style scoped>
h1 {
  font-family: Helvetica;
  font-size: 2em;
  color: #ffffff;
}
i {
  color: #cf9604;
}
h2 {
  color: #cf9604;
  font-family: Helvetica;
  font-weight: normal;
  font-size: 1.5em;
  text-transform: uppercase;
}
h4 {
  color: #828282;
}
p {
  color: #161616;
}
img {
  width: 100%;
  display: block;
  margin: auto;
  object-fit: fill;
}
.westSimple {
  width: 32%;
  height: 100%;
  float: left;
  border-right: 0.5px solid #e5e5e5;
}
.eastSimple {
  width: 68%;
  float: right;
}
.topSimple {
  display: flex;
  width: 100%;
}
section {
  padding: 0 3em;
}
.mainSimple {
  margin: 2em 0;
}
.topSimple section {
  padding: 2em 3em;
  width: 50%;
}
.topSimple section:nth-child(1) {
  background-color: #000000;
  color: white;
  float: left;
}
.topSimple section:nth-child(2) {
  float: right;
  padding-bottom: 0;
}
.topSimple section:nth-child(1) h2 {
  color: white;
  margin-bottom: 0.5em;
}
.topSimple section:nth-child(1) p {
  color: #acacac;
  margin: 0.2em;
}
.westSimple h2 {
  margin-top: 1em;
}
.progress-simple div {
  background: #161616;
}
</style>
