<template>
  <div
    class="globalTemplate"
    :style="{ fontFamily: this.cv.typeOfLetter, textAlign: this.cv.align }"
  >
    <div class="eastConcept">
      <section class="persoInfo">
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length > 0">
          -{{ cv.listExperience[0].job }}-
        </h2>
        <img v-bind:src="cv.img" alt="candidate picture" />
        <div class="darkConcept verifyPagesCv">
          <h2>{{$t('aboutMe')}}</h2>
          <p>{{ cv.bio }}</p>
        </div>
      </section>
      <section>
        <h2>{{$t('training.training')}}</h2>
        <div
          v-for="(item, index) in cv.listEducation"
          :key="index"
          class="verifyPagesCv"
        >
          <h3>{{ item.degree }}</h3>
          <h4>
            {{ item.nameSchool }} | {{ item.city }} |
            {{ item.date ? formatDate(item.date) : "..." }}
          </h4>
          <p>{{ item.bio }}</p>
        </div>
      </section>
    </div>
    <div class="westConcept">
      <section>
        <h2>{{$t('contact.contact')}}</h2>
        <div>
          <p>
            <i class="fas fa-map-marker-alt"></i>
            {{ cv.contact.address }}, {{ cv.contact.city }},
            {{ cv.contact.postal }}
          </p>
          <p>
            <i class="fas fa-phone-alt"></i>
            {{ cv.contact.phone }}
          </p>
          <p>
            <i class="fas fa-envelope"></i>
            {{ cv.contact.email }}
          </p>
        </div>
      </section>
      <section>
        <h2>{{$t('experience.experience')}}</h2>
        <div
          v-for="(item, index) in cv.listExperience"
          :key="'A' + index"
          class="verifyPagesCv"
        >
          <h3>{{ item.job }}</h3>
          <h4>
            {{ item.employer }} | {{ item.city }} |
            {{ item.dateStart ? formatDate(item.dateStart) : "..." }} ~
            {{ item.checkbox ? "Presente" : formatDate(item.dateEnd) }}
          </h4>
          <p>
            {{ item.bio }}
          </p>
        </div>
        <h2>{{$t('skill.skills')}}</h2>
        <div
          class="verifyPagesCv"
          v-for="(item, index) in cv.listSkill"
          :key="'B' + index"
        >
          {{ item.name }}
          <div class="progress-simple">
            <div v-bind:class="getStyle(item.level)"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`;
    },
  },
  mounted() {
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  },
};
</script>

<style scoped>
h1 {
  font-family: Bebas Neue;
  font-weight: normal;
  font-size: 2.8em;
  text-align: center;
  text-transform: uppercase;
  color: white;
}
.westConcept h2,
.darkConcept h2,
.eastConcept section:nth-child(2) h2 {
  font-family: Bebas Neue;
  font-weight: normal;
  font-size: 1.8em;
  letter-spacing: 0.08em;
  color: #1f425b;
  text-transform: uppercase;
}
.darkConcept h2 {
  color: white;
}
h3 {
  color: #161616;
}
h4 {
  color: #828282;
}
i {
  color: #1f425b;
  padding-right: 1em;
}
img {
  display: block;
  width: 58%;
  border-radius: 50%;
  object-fit: fill;
  margin: 1em auto;
}
p {
  font-weight: 300;
}
.persoInfo {
  background: #1f425b;
  border-radius: 0px 80px 0px 80px;
}
.persoInfo > h2 {
  font-weight: normal;
  color: #ffffff;
  text-align: center;
}
.eastConcept section,
.westConcept {
  padding: 2.5em;
}
.eastConcept {
  min-height: 100vh;
  height: 100%;
  width: 42%;
  float: left;
}
.darkConcept {
  color: white;
  text-align: center;
}
.westConcept {
  width: 58%;
  float: right;
}
.westConcept section:nth-child(1) p {
  margin: 0.2em;
}
.westConcept section:nth-child(1) {
  margin-bottom: 1em;
}
.progress-simple {
  margin-bottom: 0.8em;
}
</style>
