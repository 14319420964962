<template>
  <div
    class="globalTemplate"
    :style="{ fontFamily: this.cv.typeOfLetter, textAlign: this.cv.align }"
  >
    <section class="headerElegant">
      <div>
        <h1>{{ cv.contact.firstName }} {{ cv.contact.lastName }}</h1>
        <h2 v-if="cv.listExperience.length > 0">
          {{ cv.listExperience[0].job }}
        </h2>
      </div>
      <div>
        <p>
          <i class="fas fa-envelope"></i>
          {{ cv.contact.email }}
        </p>
        <p>
          <i class="fas fa-phone-alt"></i>
          {{ cv.contact.phone }}
        </p>
        <p>
          <i class="fas fa-map-marker-alt"></i>
          {{ cv.contact.address }},{{ cv.contact.city }},
          {{ cv.contact.postal }}
        </p>
      </div>
    </section>
    <section class="mainInfoElegant">
      <div class="westElegant">
        <img v-bind:src="cv.img" alt="candidate picture" />
        <h2>{{$t('aboutMe')}}</h2>
        <p class="verifyPagesCv">{{ cv.bio }}</p>
        <h2>{{$t('skill.skills')}}</h2>
        <div
          class="verifyPagesCv"
          v-for="(item, index) in cv.listSkill"
          :key="'A' + index"
        >
          {{ item.name }}
          <div class="progress">
            <div v-bind:class="getStyle(item.level)"></div>
          </div>
        </div>
      </div>
      <div class="eastElegant">
        <h2>{{$t('experience.experience')}}</h2>
        <div v-for="(item, index) in cv.listExperience" :key="index">
          <div class="locationWest verifyPagesCv">
            <h3>{{ item.employer }}</h3>
            <h4>{{ item.city }}</h4>
            <h4>
              {{ item.dateStart ? formatDate(item.dateStart) : "..." }} ~
              {{ item.checkbox ? "Presente" : formatDate(item.dateEnd) }}
            </h4>
          </div>

          <div class="descriptionEeast">
            <h3>{{ item.job }}</h3>
            <p>
              {{ item.bio }}
            </p>
          </div>
        </div>

        <h2>{{$t('training.training')}}</h2>
        <div v-for="(item, index) in cv.listEducation" :key="'B' + index">
          <div class="locationWest verifyPagesCv">
            <h3>{{ item.nameSchool }}</h3>
            <h4>{{ item.city }}</h4>
            <h4>{{ item.date ? formatDate(item.date) : "..." }}</h4>
          </div>
          <div class="descriptionEeast">
            <h3>{{ item.degree }}</h3>
            <p>{{ item.bio }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import utilities from "../services/utilities";

export default {
  computed: {
    ...mapState(["cv"]),
  },
  methods: {
    getStyle(item) {
      return `progress-value-${item}`;
    },
  },
  mounted() {
    utilities.setFontSizes(this, this.cv.sizeOfLetter);
    utilities.updateHeight();
  },
};
</script>
<style scoped>
h1 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 2em;
  color: #161616;
}
.mainInfoElegant h2 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.5em;
  border-bottom: 0.5px solid #e0e0e0;
  margin-top: 1em;
}
h3,
h4 {
  font-weight: 500;
}
h4 {
  color: #828282;
  font-size: 0.8em;
}
img {
  width: 80%;
  display: block;
  margin: auto;
  border-radius: 50%;
  object-fit: cover;
}
.headerElegant h2 {
  font-weight: 500;
  font-size: 1.5em;
  color: #cf9603;
  text-transform: uppercase;
}
i {
  padding-right: 1em;
  color: #cf9603;
}
.globalTemplate {
  overflow: hidden;
}
.headerElegant div {
  width: 50%;
}
.headerElegant div:nth-child(1) {
  padding: 3em;
  float: left;
}
.headerElegant div:nth-child(2) {
  background-color: #1f425b;
  padding: 2em 3em;
  float: right;
  color: white;
  border-radius: 0 0 0 3em;
}
.headerElegant p {
  margin: 0.2em;
}
.headerElegant {
  display: flex;
  clear: both;
}
.mainInfoElegant {
  width: 100%;
  height: 100%;
}
.westElegant,
.eastElegant {
  padding: 2em 3em;
}
.westElegant {
  display: block;
  height: 100%;
  width: 36%;
  border-radius: 0 3em 0 0;
  color: white;
  background-color: #1f425b;
  float: left;
}
.eastElegant {
  width: 64%;
  float: right;
}
.eastElegant > div {
  display: flex;
}
.locationWest {
  padding-right: 1em;
}
</style>
